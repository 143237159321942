.main {
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
}

.title {
    color: white;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
}
