.MuiList-subheader {
    padding-top: 0;
    width: 100%;
}

.MuiDialog-paperWidthSm {
    max-width: 1200px;
    width: 100%;
}

.MuiTypography-root {
    overflow: hidden;
}

body {
    margin: 0px;
}
